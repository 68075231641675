import React from 'react';
import TekResume from '../../assets/Tek-Raj-Joshi-Resume.pdf';

const CTA = () => {
    return (
        <div className='cta'>
            <a href={TekResume} download className='btn'>Download CV</a>
            <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
    )
}

export default CTA
