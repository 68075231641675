import React from 'react';
import './about.css';
import profile from '../../assets/tekrajProfile.jpeg';
import { FaAward } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { VscFolderLibrary } from 'react-icons/vsc';

const About = () => {
    return (
        <section id='about'>
            <h5>Get To Know</h5>
            <h2>About Me</h2>
            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={profile} alt="Kunal About" />
                    </div>
                </div>
                <div className="about__content">
                    <div className="about__cards">
                        <article className='about__card'>
                            <FaAward className='about__icon' />
                            <h5>Experience</h5>
                            <small>1.5+ Years Working</small>
                        </article>
                        <article className='about__card'>
                            <FiUsers className='about__icon' />
                            <h5>Tech Skills</h5>
                            <small>50+ wizards</small>
                        </article>
                        <article className='about__card'>
                            <VscFolderLibrary className='about__icon' />
                            <h5>Projects</h5>
                            <small>10+ Completed</small>
                        </article>
                    </div>
                    <p>
                    I'm Tek Raj Joshi, an experienced Ethereum blockchain developer passionate about decentralized systems and smart contracts. Proficient in Solidity and the Ethereum ecosystem, I specialize in building secure and scalable decentralized applications. With expertise in Ethers.js, Hardhat, and Infura, I seamlessly integrate apps with the Ethereum network. I also excel in React.js for enhanced user experiences. My dedication to clean, efficient code and timely delivery makes me a valuable asset to any blockchain team.                    </p>
                    <a href="#contact" className='btn btn-primary'>Let's Talk</a>
                </div>
            </div>
        </section>
    )
}

export default About;
